let isMenuOpen = false

export default function toggleMenu() {
  console.log(isMenuOpen)
  if (isMenuOpen) {
    document.getElementById("container").style.display = "block"
    document.getElementById("fullScreenMenu").style.display = "none"
  } else {
    document.getElementById("container").style.display = "none"
    document.getElementById("fullScreenMenu").style.display = "flex"
  }
  isMenuOpen = !isMenuOpen
}
