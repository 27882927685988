import React from "react"
import { Link } from "gatsby"
import logo from "./logo.svg"
import facebookIcon from "./social-facebook.png"
import instagramIcon from "./social-instagram.png"
// import twitterIcon from "./social-twitter.png"
// import youtubeIcon from "./social-youtube.png"
import hubspot from "./HubSpot.png"
import marketo from "./marketo.png"
import workfront from "./workfront.png"
import salesforce from "./salesforce.png"
import linkedIn from "./social-in.png"
import oracle from "./O-Sliver-Partner-clr.png"
import map from "./mapa-01.svg"

const Footer = () => {
  return (
    <section className="footer">
      <div className="footer__col">
        <div className="footer__logo">
          <img src={logo} />
        </div>
        <div className="footer__textItem">
          <p>
            Smartempo partners with the leading marketing technology vendors
            allowing our team of certified experts to provide you with the best
            solutions to power your business.
          </p>
        </div>

        <div className="footer__textItem">
          {/* <div className="subscribe">
            <div className="subscribe__input">
              <input type="text" />
            </div>
            <div className="subscribe__button">
              <button>Subscribe</button>
            </div>
          </div> */}
        </div>
      </div>
      <div className="footer__colRigth">
        <div className="footer__textItemMap">
          <div className="footer__map">
            <img src={map} />
          </div>
          <div className="footer__textItemMap__text">
            <div className="footer__textItem__text">
              <h1>AUS</h1>
              <h4>AUSTIN</h4>
              <p></p>
              <p></p>
              <p></p>
            </div>
            <div className="footer__textItem__text">
              <h1>MDE</h1>
              <h4>MEDELLIN</h4>
              <p></p>
            </div>
          </div>
        </div>
        <div className="footer__textItem">
          {/* <h4>Nearshore Center</h4> */}
        </div>
      </div>
      <div className="footer__textItem__partner">
        <h5>Our Partners</h5>
        <div className="footer__partnerImages">
          <div className="footer__partnerImages__img">
            <img src={hubspot} />
          </div>
          <div className="footer__partnerImages__img">
            <img src={marketo} />
          </div>
          <div className="footer__partnerImages__img">
            <img src={salesforce} />
          </div>
          <div className="footer__partnerImages__img">
            <img src={workfront} />
          </div>
          <div className="footer__partnerImages__img">
            <img src={oracle} />
          </div>
        </div>
      </div>
      <div className="footer__textItem">
        <h5>Follow Us</h5>
        <div className="footer__social">
          {/* <a
              href={"https://www.facebook.com/smartempoagency-801857336672390/"}
            >
              <img src={facebookIcon} />
            </a> */}
          <a
            href={"https://www.instagram.com/smartempo.agency/"}
            target="_blank"
          >
            <img src={instagramIcon} />
          </a>
          <a
            href={"https://www.linkedin.com/company/smartempo"}
            target="_blank"
          >
            <img src={linkedIn} />
          </a>
          {/* <img src={twitterIcon} /> */}
          {/* <img src={youtubeIcon} /> */}
        </div>
      </div>
      <div className="footerMenu">
        <ul>
          <li>
            <Link to="privacy-policy">Privacy Policy</Link>
          </li>
        </ul>
      </div>
    </section>
  )
}

export default Footer
