import React from "react"
import { Link } from "gatsby"
import toggleMenu from "../../utils"
import logo from "./logo.svg"
import burger from "./icon-menu.svg"
import iconSpanish from "./icon-spanish.svg"

import styles from "./topbar.module.css"

let isMobileMenuOpen = false
function toggleMenuMobile() {
  if (isMobileMenuOpen) {
    document.getElementById("menuMobile").style.display = "none"
    isMobileMenuOpen = false
  } else {
    document.getElementById("menuMobile").style.display = "flex"
    isMobileMenuOpen = true
  }
}

const Topbar = () => {
  return (
    <div>
      <section className={styles.topbar}>
        <div className={styles.topbar__logo}>
          <Link to="/">
            <img src={logo} alt="Smartempo" width="250" />
          </Link>
        </div>
        <div className={styles.topbar__menu}>
          <ul>
            <li id="services" onClick={e => toggleMenu(e)} className="cursor">
              Services
            </li>
            <li>
              <Link to="why-smartempo">Why Smartempo</Link>
            </li>
            <li>
              <Link to="contact-us">Contact Us</Link>
            </li>
            <li>
              <Link to="blog">Blog</Link>
            </li>
          </ul>
          <div className={styles.topbar__spanish}>
            <a href="/es">
              <img src={iconSpanish} alt="Spanish" width="36" />
            </a>
          </div>
        </div>
        <div
          className={styles.topbar__menu__icon}
          id="burgerMobile"
          onClick={e => toggleMenuMobile()}
        >
          <img src={burger} alt="Menu" style={{ width: "50px" }} />
        </div>
      </section>
      <section className={styles.topbar__menu__mobile} id="menuMobile">
        <ul>
          <li id="services" onClick={e => toggleMenu(e)} className="cursor">
            Services
          </li>
          <li>
            <Link to="why-smartempo">Why Smartempo</Link>
          </li>
          <li>
            <Link to="contact-us">Contact Us</Link>
          </li>
          <li>
            <Link to="blog">Blog</Link>
          </li>
          <li>
            <a href="/es">
              <img src={iconSpanish} alt="Spanish" width="26" />
            </a>
          </li>
        </ul>
      </section>
    </div>
  )
}

export default Topbar
