import React from "react"
import styles from "./fullScreenMenu.module.css"
import toggleMenu from "../../utils"
import { Link } from "gatsby"
import quotePic from "./beethoven.png"

import { useSpring, animated } from "react-spring"

const FullScreenMenu = ({ data }) => {
  return (
    <div id="fullScreenMenu" className={styles.fullScreenMenu}>
      <div
        onClick={() => toggleMenu()}
        className={styles.fullScreenMenu__close}
      >
        X
      </div>
      <ul>
        <li>
          <Link to="/marketing-technology" onClick={() => toggleMenu()}>
            Marketing Technology
          </Link>
        </li>
        <li>
          <Link to="/work-management" onClick={() => toggleMenu()}>
            Work Management
          </Link>
        </li>
        <li>
          <Link to="/strategic-consulting" onClick={() => toggleMenu()}>
            Strategic Consulting Services
          </Link>
        </li>
        <li>
          <Link to="/development" onClick={() => toggleMenu()}>
            Development
          </Link>
        </li>
      </ul>
      <div className={styles.fullScreenMenu__quote}>
        <img src={quotePic} />
        <p>
          Don't only practice your art, but force your way into its secrets -
          Ludwig Van Beethoven
        </p>
      </div>
    </div>
  )
}

export default FullScreenMenu
